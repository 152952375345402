import React from 'react'
import { ThemeProvider } from '@emotion/react'

const theme = {
  "id": 11,
  "colors": {
    "colorVariable1": "rgba(27, 39, 151, 1)",
    "colorVariable2": "rgba(24,24,24,1)",
    "colorVariableBtn1": "rgba(255,255,255,1)",
    "colorVariableBtn2": "rgba(0,0,0,1)",
    "front1": "rgba(32,32,32,1)",
    "front2": "rgba(255,255,255,1)",
    "back2": "rgba(24,24,24,1)"
  },
  "variables": {
    "color-variable-1": "rgba(215,180,25,1)",
    "color-variable-2": "rgba(247,246,237,1)",
    "color-variable-btn-1": "rgba(247,246,237,1)",
    "color-variable-btn-2": "rgba(48,56,77,1)"
  },
  "globalMobile": "27em",
  "meta": []
}

export default function ThemeWrapper(props) {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}